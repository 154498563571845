<template>
  <div id="f4cfa74f-a415-4bac-8411-251076a49948">
    <br>
    <v-row>
      <v-col cols="12" lg="8">
        <div v-if="loading" class="d-flex justify-center align-center">
          <Spinner :size="30" />
        </div>
        <ImageCarousel
          v-else-if="images.length > 0"
          :images="images"
          :fullscreen="fullscreen"
          @setFullscreen="setFullscreen"
        />
        <DefaultImage
          v-else
          :height="imageHeight"
        />

        <div class="mt-5" v-if="!$vuetify.breakpoint.lgAndUp">
          <SubscriptionDetails
            v-if="investorSubscriptionInformation"
            :investorSubscriptionInformation="investorSubscriptionInformation"
            :product="product"
            :investLoading="investLoading"
            @invest="invest"
          />
          <div v-else class="d-flex justify-center align-center">
            <Spinner :size="30" />
          </div>
        </div>

        <!-- MORE DETAILS -->
        <div class="mt-5 more-details">
          <h2 v-html="$t(`${productId}.productInfo.moreDetails.titleOne`)" />
          <div class="mt-3 d-flex flex-column">
            <div>
              <h3
                class="mt-5 align-left"
                v-html="$t(`${productId}.productInfo.moreDetails.descriptionOneTitle`)"
              />
              <p class="mt-3" v-html="$t(`${productId}.productInfo.moreDetails.descriptionOne`)" />
            </div>
            <v-img
              :src="require(`@/assets/img/products/${productId}.jpg`)"
              :height="detailsImageHeight"
              :max-width="440"
              class="rounded-custom"
            />
          </div>
          <h3 class="mt-5 align-left" v-html="$t(`${productId}.productInfo.moreDetails.descriptionTwoTitle`)" />
          <p class="mt-3" v-html="$t(`${productId}.productInfo.moreDetails.descriptionTwo`)" />
          <h3 class="mt-5 align-left" v-html="$t(`${productId}.productInfo.moreDetails.secondDescriptionTwoTitle`)" />
          <p class="mt-3" v-html="$t(`${productId}.productInfo.moreDetails.secondDescriptionTwo`)" />
          <h3 class="mt-5 align-left" v-html="$t(`${productId}.productInfo.moreDetails.thirdDescriptionTwoTitle`)" />
          <p class="mt-3" v-html="$t(`${productId}.productInfo.moreDetails.thirdDescriptionTwo`)" />
          <v-expansion-panels
            accordion
            v-model="panel"
            multiple
          >
            <v-expansion-panel :readonly="false">
              <v-expansion-panel-header color="backgroundDark" class="px-0" :disable-icon-rotate="false">
                <h2 v-html="$t(`${productId}.productInfo.moreDetails.titleTwo`)" />
                <template v-slot:actions v-if="false">
                  <v-icon class="icon">mdi-lock</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="backgroundDark" class="px-0">
                <v-row class="mt-5 align-left small-text">
                  <v-col cols="12" sm="6" md="3" lg="6" class="mb-5">
                    <v-row>
                      <v-col class="font-weight-medium big-text">COMETUM Diamant “C/1743 X1”</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Schliff</v-col>
                      <v-col>Brilliant, rund</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Art</v-col>
                      <v-col>natürlicher Diamant</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Karat</v-col>
                      <v-col>2,01</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Farbe</v-col>
                      <v-col>farblos (D)</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Klarheit</v-col>
                      <v-col>ohne Einschlüsse (IF)</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Schliff</v-col>
                      <v-col>Excellent</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Symmetrie</v-col>
                      <v-col>Excellent</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Fluoreszenz</v-col>
                      <v-col>keine</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Herkunftsland</v-col>
                      <v-col>Botswana</v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" lg="6" class="mb-5">
                    <v-row>
                      <v-col class="font-weight-medium big-text">Technische Daten</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Dimensionen</v-col>
                      <v-col>8.04 - 8,07 x 5,04 mm</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">Zertifiziert durch</v-col>
                      <v-col>International Gemological Institute (IGI)</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">IGI Report Number</v-col>
                      <v-col>480179582</v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-img
                          :src="require(`@/assets/img/products/${productId}.png`)"
                        />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" lg="6" class="mb-5">
                    <v-row>
                      <v-col class="font-weight-medium big-text">Farbe - Skala</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">D-F</v-col>
                      <v-col>colorless</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">G-J</v-col>
                      <v-col>near colorless</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">K-M</v-col>
                      <v-col>slightly tinted</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">N-R</v-col>
                      <v-col>very light color</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">S-Z</v-col>
                      <v-col>light color</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">ohne</v-col>
                      <v-col>fancy</v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" lg="6" class="mb-5">
                    <v-row>
                      <v-col class="font-weight-medium big-text">Klarheit - Skala</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">IF 1-2</v-col>
                      <v-col>internally flawless</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">VVS 1-2</v-col>
                      <v-col>very very slightly included</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">VS 1-2</v-col>
                      <v-col>very slightly included</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">SI 1-2</v-col>
                      <v-col>slightly included</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grey--text">I 1-3</v-col>
                      <v-col>included</v-col>
                    </v-row>
                  </v-col>

                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :readonly="false">
              <v-expansion-panel-header color="backgroundDark" class="px-0" :disable-icon-rotate="false">
                <h2 v-html="$t(`${productId}.productInfo.moreDetails.titleThree`)" />
                <template v-slot:actions v-if="false">
                  <v-icon class="icon">mdi-lock</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="backgroundDark" class="px-0">
                <p class="mt-5" v-html="$t(`${productId}.productInfo.moreDetails.descriptionFour`)" />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :readonly="!onBoardingCompleted">
              <v-expansion-panel-header color="backgroundDark" class="px-0" :disable-icon-rotate="!onBoardingCompleted">
                <h2 v-html="$t(`${productId}.productInfo.moreDetails.titleFour`)" />
                <template v-slot:actions v-if="!onBoardingCompleted">
                  <v-icon class="icon">mdi-lock</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="backgroundDark" class="px-0">
                <p class="mt-5" v-html="$t(`${productId}.productInfo.moreDetails.descriptionFive`)" />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :readonly="!onBoardingCompleted">
              <v-expansion-panel-header color="backgroundDark" class="px-0" :disable-icon-rotate="!onBoardingCompleted">
                <h2 v-html="$t(`${productId}.productInfo.moreDetails.titleFive`)" />
                <template v-slot:actions v-if="!onBoardingCompleted">
                  <v-icon class="icon">mdi-lock</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="backgroundDark" class="px-0">
                <p class="mt-5" v-html="$t(`${productId}.productInfo.moreDetails.descriptionSix`)" />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel :readonly="!onBoardingCompleted">
              <v-expansion-panel-header color="backgroundDark" class="px-0" :disable-icon-rotate="!onBoardingCompleted">
                <h2 v-html="$t(`${productId}.productInfo.moreDetails.titleSix`)" />
                <template v-slot:actions v-if="!onBoardingCompleted">
                  <v-icon class="icon">mdi-lock</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content color="backgroundDark" class="px-0">
                <p class="mt-5" v-html="$t(`${productId}.productInfo.moreDetails.descriptionSeven`)" />
              </v-expansion-panel-content>
            </v-expansion-panel>

          </v-expansion-panels>

          <div class="d-flex mt-2">
            <h2 v-html="$t(`${productId}.productInfo.moreDetails.titleSeven`)" />
            <v-icon v-if="!onBoardingCompleted" class="ml-3">mdi-lock</v-icon>
          </div>
          <div v-if="onBoardingCompleted">
            <div v-if="loading" class="d-flex justify-center">
              <Spinner :size="30" />
            </div>
            <div v-else class="mt-5 d-flex flex-column">
              <Document
                v-for="(document, index) in documents"
                :key="index"
                :document="document"
                :index="index"
              />
            </div>
          </div>
          <h2 v-html="$t(`${productId}.productInfo.moreDetails.titleEight`)" class="mt-5" />
          <p class="mt-5" v-html="$t(`${productId}.productInfo.moreDetails.descriptionEight`)" />
        </div>
      </v-col>

      <v-col v-if="$vuetify.breakpoint.lgAndUp" lg="4">
        <SubscriptionDetails
          v-if="investorSubscriptionInformation"
          :class="onBoardingCompleted ? 'subscription-details-onboarded' : 'subscription-details'"
          :investorSubscriptionInformation="investorSubscriptionInformation"
          :product="product"
          :investLoading="investLoading"
          @invest="invest"
        />
        <div v-else class="d-flex justify-center align-center">
          <Spinner :size="30" />
        </div>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';
import clone from '@/utils/clone';
import pdpDefaultDocs from '@/config/pdpDefaultDocs';
import Spinner from '@/components/Shared/Spinner.vue';

export default {
  name: 'f4cfa74f-a415-4bac-8411-251076a49948',
  components: {
    DefaultImage: lazyLoad('components/Assets/DefaultImage'),
    Document: lazyLoad('components/Marketplace/NewDeals/ProductDetails/ProductDocument'),
    ImageCarousel: lazyLoad('components/Marketplace/NewDeals/ProductDetails/ImageCarousel'),
    SubscriptionDetails: lazyLoad('components/Marketplace/NewDeals/ProductDetails/SubscriptionDetails'),
    Spinner,
  },
  props: {
    product: {
      type: Object,
    },
    investorSubscriptionInformation: {
      type: Object,
    },
    investLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      images: [],
      loading: true,
      fullscreen: false,
      onboardingModal: false,
      panel: [],
      documents: clone(pdpDefaultDocs),
    };
  },
  computed: {
    ...mapGetters('user', [
      'onBoardingCompleted',
    ]),
    imageHeight() {
      return this.fullscreen ? 700 : 456;
    },
    detailsImageHeight() {
      if (this.$vuetify.breakpoint.width >= 470) {
        return 226;
      }
      if (this.$vuetify.breakpoint.width >= 400) {
        return 186;
      }
      return 156;
    },
    productId() {
      return this.$route.params.product;
    },
    assetStatus() {
      return this.product.assetStatus;
    },
  },
  async created() {
    const images = await this.$store.dispatch('assets/getImages', {
      category: 'Supporting',
      assetId: this.product.id,
    });
    if (images.length > 0) {
      this.images = images;
    }
    const documents = await this.$store.dispatch('assets/getDocuments', {
      category: 'ProductDetailPage',
      assetId: this.product.id,
    });
    if (documents.length > 0) {
      documents.forEach((item) => {
        this.documents.push({
          documentTemplateId: item.key,
          documentKey: item.documents[0].key,
          title: item.title,
        });
      });
    }
    this.loading = false;
  },
  methods: {
    ...mapActions('onboarding', [
      'setOnboardingModal',
    ]),
    invest() {
      if (this.onBoardingCompleted) {
        this.$emit('invest');
      } else {
        this.setOnboardingModal(true);
      }
    },
    setFullscreen(value) {
      this.fullscreen = value;
    },
  },
};
</script>

<style lang="scss">
#f4cfa74f-a415-4bac-8411-251076a49948 {
  .subscription-details {
    position: fixed;
    overflow-y: scroll;
    top: 122px;
    width: 25%;
    height: 86vh;
  }
  .subscription-details-onboarded {
    position: fixed;
    overflow-y: scroll;
    top: 80px;
    width: 25%;
    height: 86vh;
  }
  .align-right {
    text-align: right;
  }
  .align-left {
    text-align: left;
  }
  .field-desc {
    font-size: 12px;
  }
  .v-divider {
    margin: .5em 0 1em 0;
  }
  .v-slider {
    margin: 0;
  }
  .xs-text {
    font-size: 10px;
  }
  .small-text {
    font-size: 12px;
  }
  .medium-text {
    font-size: 13px;
  }
  .big-text {
    font-size: 16px;
  }
  .v-skeleton-loader__image.v-skeleton-loader__bone {
    height: 400px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .more-details {
    text-align: justify;
  }
  .tooltip-text {
    font-size: 50px !important;
    opacity: 1 !important;
    display: block !important;
    color: red !important;
  }
  .v-expansion-panel-content__wrap {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .v-expansion-panel-header__icon {
    margin-left: 20px !important;
  }
  .v-expansion-panel-header>:not(.v-expansion-panel-header__icon) {
    flex-grow: 0 !important;
  }
  .v-expansion-panel:not(:first-child):after {
    border-top: 0px !important;
  }
  .v-expansion-panel:before {
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0 !important;
  }
  .paper-icon {
    transform: scale(1.5);
  }
  .rounded-custom {
    border-radius: 10px !important;
  }
}
</style>
